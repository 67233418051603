import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input", "nestedField"];

  initialize() {
    this.attach = this._attach.bind(this);
  }

  connect() {
    this._attach();
    this.element.addEventListener("flatpickr:resetForm", this.attach);
  }

  disconnect() {
    this.element.removeEventListener("flatpickr:resetForm", this.attach);
  }

  _attach() {
    this.inputTargets.forEach((input) =>
      flatpickr(input, {
        weekNumbers: true,
        altInput: true,
        altFormat: "d M, Y",
      })
    );
  }
}
