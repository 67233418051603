import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    this.stimulateHandler = this.handleStimulate.bind(this);
  }

  connect() {
    super.connect();
    this.element.addEventListener("stimulate", this.stimulateHandler);
  }

  disconnect() {
    this.element.removeEventListener("stimulate", this.stimulateHandler);
  }

  async handleStimulate({ detail }) {
    // Stimulate reflex
    const response = await this.stimulate(
      detail.reflex,
      this.element,
      {
        resolveLate: true,
      },
      ...(detail.args || [])
    );

    // Return if caller not provided
    if (!detail.caller) return;

    // Return if morph halted
    const reflexAction = response.event.detail.name;
    if (reflexAction === "stimulus-reflex:morph-halted") return;

    // Dispatch afterProxy event on element
    const eventName = `afterProxy:${detail.caller}`;
    const payload = response.event.payload;
    const event = new CustomEvent(eventName, { detail: payload });
    this.element.dispatchEvent(event);
  }
}
