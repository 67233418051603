import { Controller } from "stimulus";

export default class extends Controller {
  static values = { path: String, attribute: String };

  initialize() {
    this.save = this._save.bind(this);
    this.paste = this._paste.bind(this);
  }

  connect() {
    this.element.setAttribute("contenteditable", true);
    this.element.classList.add("focus:outline-none");
    this._setListeners("add");
  }

  disconnect() {
    this._setListeners("remove");
  }

  _save() {
    fetch(this.pathValue, {
      method: "PATCH",
      headers: this.headers,
      body: this.body,
    });
  }

  _paste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
    this.save();
  }

  _setListeners(verb) {
    const action = `${verb}EventListener`;
    this.element[action]("debounced:input", this.save);
    this.element[action]("blur", this.save);
    this.element[action]("paste", this.paste);
  }

  get headers() {
    return {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
    };
  }

  get body() {
    return JSON.stringify({
      attribute: this.attributeValue,
      value: this.element.textContent,
    });
  }
}
