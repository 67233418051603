import { Controller } from "stimulus";
import Animation from "../helpers/animation_helper";
import setUserState from "../helpers/user_state_helper";

export default class extends Controller {
  initialize() {
    this.animation = new Animation();
    this.toggleHandler = this.toggle.bind(this);
  }

  connect() {
    this.trigger.addEventListener("click", this.toggleHandler);
    this.setTriggerState();
    super.connect();
  }

  disconnect() {
    this.trigger.removeEventListener("click", this.toggleHandler);
  }

  setTriggerState() {
    if (this.isDisplayed) this.trigger.classList.add("bg-gray-button-active");
  }

  toggle() {
    this.element.style.removeProperty("width");

    this.animation[this.movement](this.element, false);
    this.trigger.classList.toggle("bg-gray-button-active");
    this.updateUserState();
  }

  updateUserState() {
    return setUserState({
      attribute: "show_sidebar",
      value: !this.isDisplayed,
    });
  }

  get isDisplayed() {
    return this.element.classList.contains("translate-x-0");
  }

  get movement() {
    return this.isDisplayed ? "exit" : "enter";
  }

  get trigger() {
    return (this._trigger ||= document.getElementById("sidebar-trigger"));
  }
}
