import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "addComment",
    "BionicGPT",
    "bullet",
    "completeText",
    "keyOpportunity",
    "dividingPage",
    "embed",
    "footnote",
    "forReview",
    "heading",
    "image",
    "insertTemplate",
    "linkToTopic",
    "moveBottom",
    "moveTo",
    "moveTop",
    "removeTemplateAssociation",
    "saveAsTemplate",
    "syncGlossaryItem",
    "syncTemplate",
    "viewEditHistory",
    "viewEditHistory",
    "wasMoved",
    "wasProofread",
    "workInProgress",
  ];

  static values = { turboFrame: String, isImageTitle: Boolean };

  connect() {
    this.requiresFrameLoad = this.hasTurboFrameValue;

    if (this.hasTurboFrameValue) {
      this.frame = document.getElementById(this.turboFrameValue);
    }
  }

  handleKeydown(event) {
    if (this.requiresFrameLoad && this.frame.hasAttribute("loading")) {
      return this._loadFrame(event);
    }

    this._highlights(event);
    this._templates(event);
    this._blocks(event);
    this._ai(event);
    this._positioning(event);
    this._misc(event);

    // For keydown events that aren't present in the commands dropdown
    this._other(event);
  }

  _templates(event) {
    // cmd + control + t
    if (event.metaKey && event.ctrlKey && event.key === "t") {
      if (!this.insertTemplateTarget) return;

      event.preventDefault();
      this.insertTemplateTarget.click();
    }

    // cmd + control + a
    if (event.metaKey && event.ctrlKey && event.key === "a") {
      if (!this.saveAsTemplateTarget) return;

      event.preventDefault();
      this.saveAsTemplateTarget.click();
    }

    // cmd + control + x
    if (event.metaKey && event.ctrlKey && event.key === "x") {
      if (!this.removeTemplateAssociationTarget) return;

      event.preventDefault();
      this.removeTemplateAssociationTarget.click();
    }

    // cmd + control + u
    if (event.metaKey && event.ctrlKey && event.key === "u") {
      if (!this.syncTemplateTarget) return;

      event.preventDefault();
      this.syncTemplateTarget.click();
    }
  }

  _ai(event) {
    // cmd + control + n
    if (event.metaKey && event.ctrlKey && event.key === "n") {
      if (!this.completeTextTarget) return;

      event.preventDefault();
      event.stopImmediatePropagation();
      this.completeTextTarget.click();
    }

    // cmd + control + o
    if (event.metaKey && event.ctrlKey && event.key === "o") {
      if (!this.BionicGPTTarget) return;

      event.preventDefault();
      this.BionicGPTTarget.click();
    }
  }

  _positioning(event) {
    // cmd + control + right
    if (event.metaKey && event.ctrlKey && event.key === "ArrowRight") {
      if (!this.hasMoveToTarget && !this.isImageTitleValue) return;
      event.preventDefault();

      const self = this.hasMoveToTarget ? this : this.parentController;
      return self.moveToTarget.click();
    }

    // cmd + control + up
    if (event.metaKey && event.ctrlKey && event.key === "ArrowUp") {
      if (!this.hasMoveTopTarget && !this.isImageTitleValue) return;
      if (!confirm("Move element to top?")) return;
      event.preventDefault();

      const self = this.hasMoveTopTarget ? this : this.parentController;
      return self.moveTopTarget.click();
    }

    // cmd + control + down
    if (event.metaKey && event.ctrlKey && event.key === "ArrowDown") {
      if (!this.hasMoveBottomTarget && !this.isImageTitleValue) return;
      if (!confirm("Move element to bottom?")) return;
      event.preventDefault();

      const self = this.hasMoveBottomTarget ? this : this.parentController;
      return self.moveBottomTarget.click();
    }
  }

  _blocks(event) {
    // cmd + control + h
    if (event.metaKey && event.ctrlKey && event.key === "h") {
      if (!this.headingTarget) return;

      event.preventDefault();
      this.headingTarget.click();
    }

    // cmd + control + b
    if (event.metaKey && event.ctrlKey && event.key === "b") {
      if (!this.bulletTarget) return;

      event.preventDefault();
      this.bulletTarget.click();
    }

    // cmd + control + i
    if (event.metaKey && event.ctrlKey && event.key === "i") {
      if (!this.imageTarget) return;
      if (event.target.innerText.length > 0) return;

      event.preventDefault();
      this.imageTarget.click();
    }

    // cmd + control + e
    if (event.metaKey && event.ctrlKey && event.key === "e") {
      if (!this.embedTarget) return;
      if (event.target.innerText.length > 0) return;

      event.preventDefault();
      this.embedTarget.click();
    }

    // cmd + control + v
    if (event.metaKey && event.ctrlKey && event.key === "v") {
      if (!this.dividingPageTarget) return;

      event.preventDefault();
      event.target.blur();
      this.dividingPageTarget.click();
    }
  }

  _misc(event) {
    // cmd + control + c
    if (event.metaKey && event.ctrlKey && event.key === "c") {
      if (!this.addCommentTarget) return;

      event.preventDefault();
      this.addCommentTarget.click();
    }

    // cmd + control + k
    if (event.metaKey && event.ctrlKey && event.key === "k") {
      if (!this.keyOpportunityTarget) return;

      event.preventDefault();
      this.keyOpportunityTarget.click();
    }

    // cmd + control + l
    if (event.metaKey && event.ctrlKey && event.key === "l") {
      if (!this.linkToTopicTarget) return;

      event.preventDefault();
      this.linkToTopicTarget.click();
    }

    // cmd + control + d
    if (event.metaKey && event.ctrlKey && event.key === "d") {
      if (!this.viewEditHistoryTarget) return;

      event.preventDefault();
      this.viewEditHistoryTarget.click();
    }

    // cmd + control + g
    if (event.metaKey && event.ctrlKey && event.key === "g") {
      if (!this.syncGlossaryItemTarget) return;

      event.preventDefault();
      this.syncGlossaryItemTarget.click();
    }
  }

  _highlights(event) {
    // cmd + control + r
    if (event.metaKey && event.ctrlKey && event.key === "r") {
      if (!this.forReviewTarget) return;

      event.preventDefault();
      this.forReviewTarget.click();
    }

    // cmd + control + m
    if (event.metaKey && event.ctrlKey && event.key === "m") {
      if (!this.wasMovedTarget) return;

      event.preventDefault();
      this.wasMovedTarget.click();
    }

    // cmd + control + w
    if (event.metaKey && event.ctrlKey && event.key === "w") {
      if (!this.workInProgressTarget) return;

      event.preventDefault();
      this.workInProgressTarget.click();
    }

    // cmd + control + y
    if (event.metaKey && event.ctrlKey && event.key === "y") {
      if (!this.wasProofreadTarget) return;

      event.preventDefault();
      this.wasProofreadTarget.click();
    }
  }

  _other(event) {
    // cmd + control + f
    if (event.metaKey && event.ctrlKey && event.key === "f") {
      if (!this.footnoteTarget) return;

      event.preventDefault();
      this.footnoteTarget.displayFootnoteModal();
    }
  }

  // Ensure lazy turbo frame is loaded if targets exist within frame
  _loadFrame(event) {
    this.frame.addEventListener("turbo:frame-load", () => {
      this.handleKeydown(event);
    });

    this.frame.removeAttribute("loading");
    this.requiresFrameLoad = false;
  }

  get parentController() {
    const parent = this.element.parentElement;
    const parentBlock = parent.closest("content-element-block");

    return this.application.getControllerForElementAndIdentifier(
      parentBlock,
      "content-elements--commands"
    );
  }

  get imageTitle() {
    return this.elementTypeValue === "imageTitle";
  }
}
