import { LitElement } from "lit";
import { customElement, state, property } from "lit/decorators.js";
import ReflexProxyMixin from "./mixins/reflex-proxy-mixin";
import consumer from "../channels/consumer";

@customElement("inline-edit")
export default class InlineEditElement extends ReflexProxyMixin(LitElement) {
  @state()
  _saving = false;

  @property({ type: String })
  initialValue = "";

  @property({ type: Boolean })
  shouldSaveOnInput = false;

  constructor() {
    super();

    this.consumer = consumer;
  }

  connectedCallback() {
    super.connectedCallback();
    this.toggleEventListeners("addEventListener");
    if (this.autofocus) this.focus();
  }

  disconnectedCallback() {
    this.toggleEventListeners("removeEventListener");
  }

  toggleEventListeners(action) {
    this[action]("blur", this.save.bind(this));
    this[action]("input", this.handleInput.bind(this));
    this[action]("keydown", this.handleKeydown.bind(this));
    this[action]("paste", this.handlePaste.bind(this));

    if (this.shouldSaveOnInput) {
      this[action]("debounced:input", this.saveOnInput.bind(this));
    }
  }

  createRenderRoot() {
    return this;
  }

  cancel(e) {
    e.preventDefault();
    e.stopPropagation();
    this.innerText = this.initialValue;
    this.save();
  }

  saveOnEnter(event) {
    event.preventDefault();
    this.save();
  }

  save(e) {
    if (!this.consumer.connection.isOpen()) {
      return alert(
        "The websocket connection has not been established.\n\nPlease refresh the page."
      );
    }

    if (this._saving) return;

    e?.preventDefault();

    this._saving = true;

    if (e?.type !== "blur") this.blur();

    this.stimulate(
      "InlineEdit#save",
      [this.innerText],
      `inline-edit-${this.dataset.sgid}`
    );

    this.afterProxy(`inline-edit-${this.dataset.sgid}`, () => {
      this.initialValue = this.innerText;
      this._saving = false;
    });
  }

  saveOnInput() {
    if (!this.consumer.connection.isOpen()) {
      return alert(
        "The websocket connection has not been established.\n\nPlease refresh the page."
      );
    }

    if (this._saving) return;

    this._saving = true;

    this.stimulate(
      "InlineEdit#save_on_input",
      [this.innerText],
      `inline-edit-${this.dataset.sgid}`
    );

    this.afterProxy(`inline-edit-${this.dataset.sgid}`, () => {
      this._saving = false;
    });
  }

  focusOnNext(event) {
    event.preventDefault();
    this.nextEditable?.focus();
  }

  focusOnPrevious(event) {
    event.preventDefault();
    this.previousEditable?.focus();
  }

  handleInput({ inputType }) {
    if (inputType !== "historyUndo" || this === document.activeElement) return;

    this.focus();
  }

  handleKeydown(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      return this.saveOnEnter(event);
    }

    if (event.key === "Escape") {
      return this.cancel(event);
    }

    if (event.key === "Tab" && event.shiftKey) {
      return this.focusOnPrevious(event);
    }

    if (event.key === "Tab" && !event.shiftKey) {
      return this.focusOnNext(event);
    }

    if (event.key === "b" && (event.ctrlKey || event.metaKey)) {
      return event.preventDefault();
    }

    if (event.key === "i" && (event.ctrlKey || event.metaKey)) {
      return event.preventDefault();
    }
  }

  // Removes all formatting on paste
  handlePaste(event) {
    event.preventDefault();

    const clipboardData = (event.originalEvent || event).clipboardData;

    const text = clipboardData.getData("text/plain");
    const element = document.createElement("div");
    element.innerText = text;

    document.execCommand(
      "insertText",
      false,
      element.innerText.replace(/[\n\r\t]/gm, "")
    );
  }

  get nextEditable() {
    return document.querySelectorAll("inline-edit")[this.editableIndex + 1];
  }

  get previousEditable() {
    return document.querySelectorAll("inline-edit")[this.editableIndex - 1];
  }

  get editableIndex() {
    return [...document.querySelectorAll("inline-edit")].findIndex(
      (inlineEdit) => inlineEdit === this
    );
  }
}
