import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.activeQuestion = null;
    this.activeTopic = null;
    this.setPresence = new CustomEvent("workshop:set_presence");
    this.unsetPresence = new CustomEvent("workshop:unset_presence");
    this.main = document.querySelector("main");
    this.clear = this._clear.bind(this);
    this.activeQuestionFromHash = this._activateQuestionFromHash.bind(this);
  }

  connect() {
    this.activeQuestionFromHash();
    window.addEventListener("hashchange", this.activeQuestionFromHash);
  }

  disconnect() {
    this._deactivateAll();
    this.main.removeEventListener("click", this.clear);
    window.removeEventListener("hashchange", this.activeQuestionFromHash);
  }

  setActive(event) {
    // Don't allow click event to bubble outside of question cards
    const question = event.currentTarget;

    // Bail here if element is already active
    if (question === this.activeQuestion) return;

    this._activateQuestion(question);
  }

  _activateQuestionFromHash() {
    const hash = window.location.hash;
    const hashElement = hash ? document.querySelector(hash) : null;
    const question = this._hashElementToQuestion(hashElement);
    question && this._activateQuestion(question);
  }

  _activateQuestion(question) {
    // Deactivate the previous active question
    const previousActiveQuestion = this.activeQuestion;
    this._deactivateQuestion(previousActiveQuestion);

    // Set the new active question
    this.activeQuestion = question;

    // Set active UI and update presence container
    this.activeQuestion.classList.add("!border-gray-800");
    this.activeQuestion.dispatchEvent(this.setPresence);

    // Set the new active topic
    const previousActiveTopic = this.activeTopic;
    this.activeTopic = this._fetchTopic(this.activeQuestion);

    // Update topic presence
    if (this.activeTopic !== previousActiveTopic) {
      this._activateTopic(this.activeTopic);
      this._deactivateTopic(previousActiveTopic);
    }

    // Remove presence on click
    this._setClearListener();
  }

  _deactivateQuestion(question) {
    if (!question) return;

    // Set inactive UI and update presence container
    question.classList.remove("!border-gray-800");
    question.dispatchEvent(this.unsetPresence);
  }

  _activateTopic(topic) {
    if (!topic) return;

    // Update presence container
    topic.dispatchEvent(this.setPresence);
  }

  _deactivateTopic(topic) {
    if (!topic) return;

    // Update presence container
    topic.dispatchEvent(this.unsetPresence);
  }

  _deactivateAll() {
    // Deactivate question presence
    this._deactivateQuestion(this.activeQuestion);
    this.activeQuestion = null;

    // Deactivate topic presence
    this._deactivateTopic(this.activeTopic);
    this.activeTopic = null;
  }

  _setClearListener() {
    setTimeout(() => {
      this.main.addEventListener("click", this.clear, { once: true });
    }, 0);
  }

  _clear(event) {
    if (!event) return this._deactivateAll();

    const target = event.target;
    if (!target) return this._deactivateAll();

    // Reset clear listener if user clicked on a question card
    if (target.getAttribute("workshop_question_card"))
      return this._setClearListener();

    // Reset clear listener if user clicked inside a question card
    if (target.closest("[workshop_question_card]"))
      return this._setClearListener();

    this._deactivateAll();
  }

  _fetchTopic(question) {
    return question.closest("[workshop_topic]");
  }

  _hashElementToQuestion(element) {
    if (!element) return;
    if (!element.id.includes("question")) return;

    return element.querySelector("[workshop_question_card]");
  }
}
