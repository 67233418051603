import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body", "markDone", "showLess", "showMore"];

  initialize() {
    this.afterMarkDone = this._afterMarkDone.bind(this);
  }

  showMore() {
    this.bodyTarget.classList.remove(this.maxHeightClass);
    this.showMoreTarget.classList.add("hidden");
    this.showLessTarget.classList.remove("hidden");
    this.showLessTarget.classList.add("flex");
  }

  showLess() {
    this.bodyTarget.classList.add(this.maxHeightClass);
    this.showMoreTarget.classList.remove("hidden");
    this.showLessTarget.classList.add("hidden");
    this.showLessTarget.classList.remove("flex");
  }

  markDone(event) {
    event.preventDefault();
    this.markDoneTarget.classList.toggle("text-green-accent");

    this.element.addEventListener("transitionend", this.afterMarkDone, {
      once: true,
    });

    this.element.classList.add("opacity-0", "scale-90");
  }

  _afterMarkDone() {
    this.markDoneForm.requestSubmit();
  }

  get markDoneForm() {
    return (this._markDoneForm ||= this.markDoneTarget.closest("form"));
  }

  get maxHeightClass() {
    return "max-h-[24rem]";
  }

  get taskListFrame() {
    return (this._taskListFrame ||= document.getElementById("tasks"));
  }
}
