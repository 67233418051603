// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("channels");
require("turbo_extensions");
require("stylesheets/application.scss");

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "@hotwired/turbo-rails";
import debounced from "debounced";

debounced.initialize({ ...debounced.events, input: { wait: 200 } });

import "controllers";
import "components";

// Ensure same-page anchors are not intercepted by Turbo
document.addEventListener("turbo:click", (event) => {
  let target = event.target;

  // Traverse up the DOM tree to find an anchor tag
  while (target && target.tagName !== "A") {
    target = target.parentElement;
  }

  if (!target) return;

  const href = target.getAttribute("href");
  if (href && href.startsWith("#")) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
});
