class PresenceContainer extends HTMLElement {
  async connectedCallback() {
    await this._streamConnected();

    this.observer = this._buildObserver();
    this.url = `/presence?${this.queryParams}`;
    this.csrf = document.head.querySelector("[name='csrf-token']").content;

    this._setEventListeners();
    this._fetchUsers();
  }

  async _streamConnected() {
    const stream = await this._getStream();
    if (!stream) return;

    // Ensure subscription is established
    let attempts = 0;
    let timeout = 0;

    while (!stream.subscription) {
      attempts += 1;
      timeout += 10;
      await new Promise((r) => setTimeout(r, timeout));
      if (attempts > 200) throw new Error("Unable to subscribe to stream");
    }

    // Ensure connection is established
    const connection = stream.subscription.consumer.connection;
    attempts = 0;
    while (connection.disconnected) {
      attempts += 1;
      timeout += 10;

      await new Promise((r) => setTimeout(r, timeout));
      if (attempts > 200) throw new Error("Unable to connect to stream");
    }
  }

  async _getStream() {
    let stream;
    let attempts = 0;
    let timeout = 0;
    const maxAttempts = 10;

    while (!stream && attempts < maxAttempts) {
      stream = document.getElementById("presence_stream");
      attempts += 1;
      timeout += 10;
      if (!stream) await new Promise((r) => setTimeout(r, timeout));
    }

    return stream;
  }

  _setEventListeners() {
    if (!this.hasTargetAttributes) return;

    const targetId = this.dataset.targetElement;
    const target = document.getElementById(targetId);
    if (!target) return this._setMutationObserver(targetId);

    target.addEventListener(this.dataset.addOn, this._addUser.bind(this));
    target.addEventListener(this.dataset.removeOn, this._removeUser.bind(this));
  }

  // Add mutation observer that waits for an element with id targetId to be added to the DOM
  _setMutationObserver(targetId) {
    const observer = new MutationObserver(() => {
      const target = document.getElementById(targetId);
      if (!target) return;

      this._setEventListeners();
      observer.disconnect();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  _fetchUsers() {
    if (this.addCurrentUser) {
      fetch(`/presence?${this.queryParams}`);
    } else {
      this.observer.observe(this);
    }
  }

  _addUser() {
    fetch(this.url, {
      method: "POST",
      headers: { "X-CSRF-Token": this.csrf },
    });

    // Make sure the user is featured in the main presence list
    // if they've been added to another (non-main) presence list on the page
    if (this.isMain) return;
    this.mainPresenceList && this.mainPresenceList._addUser();
  }

  _removeUser() {
    fetch(this.url, {
      method: "DELETE",
      headers: { "X-CSRF-Token": this.csrf },
    });
  }

  _buildObserver() {
    return new IntersectionObserver(this._observerCallback.bind(this), {
      root: document.body,
    });
  }

  _observerCallback([event]) {
    if (event.isIntersecting) {
      fetch(`/presence?${this.queryParams}`);
      this.observer.unobserve(this);
    }
  }

  get queryParams() {
    return [
      `page=${window.location.pathname}`,
      `id=${this.dataset.presenceListId}`,
      `add_current_user=${this.addCurrentUser}`,
    ].join("&");
  }

  get addCurrentUser() {
    return this.dataset.addCurrentUser === "true";
  }

  get hasTargetAttributes() {
    const { targetElement, addOn, removeOn } = this.dataset;
    return targetElement && addOn && removeOn;
  }

  get isMain() {
    return this.dataset.isMain === "true";
  }

  get mainPresenceList() {
    return document.querySelector("presence-container[data-is-main='true']");
  }
}

customElements.define("presence-container", PresenceContainer);
