// Used by `_replica` counters (`views/utilities/counters/_replica.html.erb`)
//
// We attach the controller to a replica and then set the target (source)
// element. Every time the source element is updated (i.e. the counter value
// changes), we set that value inside the replica.
//
// This allows us to circumvent a limitation of `updates_for` wherein we can
// only set updates_for on each record once per page (making it impossible to
// e.g. set it once on an element in the sidebar and again on the same element
// in the main pane).
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { target: String };

  async connect() {
    await new Promise((r) => setTimeout(r, 1));
    if (!this.target) return;

    this.element.innerHTML = this.target.innerHTML;

    this.target.addEventListener("cable-ready:after-update", async () => {
      await new Promise((r) => setTimeout(r, 1));
      this.element.innerHTML = this.target.innerHTML;
    });
  }

  get target() {
    return document.getElementById(this.targetValue);
  }
}
