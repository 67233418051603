import ApplicationController from "../../../../javascript/controllers/application_controller";
import { moveCursorToEnd } from "../../../../javascript/helpers/text_helper";

export default class extends ApplicationController {
  static targets = ["input"];
  static values = { reflex: String };

  connect() {
    super.connect();
  }

  focusOnInput() {
    moveCursorToEnd(this.inputTarget);
  }

  submitOnEnter({ key, currentTarget }) {
    if (key !== "Enter") return;

    this.stimulate(this.reflexValue, currentTarget);
  }
}
