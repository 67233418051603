import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import CursorPositionMixin from "./mixins/cursor-position-mixin";

@customElement("project-variable-error")
class ProjectVariableError extends CursorPositionMixin(LitElement) {
  @property({ type: Boolean })
  setFocusOnSibling = false;

  firstUpdated() {
    const observer = new MutationObserver(this._mutationCallback.bind(this));
    observer.observe(this, this._mutationConfig);
    this._handleCursorPosition();
  }

  createRenderRoot() {
    return this;
  }

  _mutationCallback() {
    const splitContent = this.textContent.split(/\s/);

    // Return if the content has no spaces
    if (splitContent.length <= 1) return;

    // Move space before error tags if pressed at the beginning
    if (splitContent[0].length === 0) {
      this.innerHTML = splitContent[1];
      this.insertAdjacentHTML("beforeBegin", "&nbsp;");
      return;
    }

    // Move content after space outside of error tags
    const errorTag = `<project-variable-error setFocusOnSibling="true">${splitContent[0]}</project-variable-error>`;
    const nextSibling = `&nbsp;${splitContent[1]}`;
    this.outerHTML = `${errorTag}${nextSibling}`;
  }

  _handleCursorPosition() {
    if (this.setFocusOnSibling) {
      this.restoreCursorPosition(this.nextSibling, 1);
      this.setFocusOnSibling = false;
    }
  }

  get _mutationConfig() {
    return {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    };
  }

  get input() {
    if (typeof this._input === "undefined") {
      this._input = this.closest("content-element-input");
    }

    return this._input;
  }
}
