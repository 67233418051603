// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import StimulusReflex from "stimulus_reflex";

import consumer from "../channels/consumer";
import controller from "./application_controller";
import hotkeys from "hotkeys-js";
import CableReady from "cable_ready";

hotkeys.filter = () => true;

const application = Application.start();
application.consumer = consumer;
const context = require.context("controllers", true, /_controller\.js$/);
const context_components = require.context(
  "../../components",
  true,
  /_controller.js$/
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
);

const componentContext = require.context(
  "../../components/",
  true,
  /(.*)\/.+\.js$/
);
application.load(definitionsFromContext(componentContext));

StimulusReflex.initialize(application, {
  controller,
  debug: true,
  isolate: true,
});
CableReady.initialize({ consumer });
