import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("content-element-block")
class ContentElementBlock extends LitElement {
  @property({ type: String })
  footnoteModalUrl;

  @property({ type: String })
  linkModalUrl;

  @property({ type: String })
  commandsDropdownId;

  @property({ type: String })
  authorUserId;

  @property({ type: Boolean })
  isImage = false;

  @property({ type: Boolean })
  shouldFocus = false;

  @property({ type: Boolean })
  disableUnlessAuthor = false;

  connectedCallback() {
    super.connectedCallback();
    this.setDisabledState();
    this.addEventListener("turbo:morphed", this.setDisabledState.bind(this));
  }

  save() {
    fetch(`/content_elements/${this.contentElementId}/saves`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({ content: this.input.content }),
    });
  }

  split(beforeCursor, afterCursor) {
    fetch(`/content_elements/${this.contentElementId}/splits`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({
        before_cursor: beforeCursor,
        after_cursor: afterCursor,
      }),
    });
  }

  mergePrevious() {
    fetch(`/content_elements/${this.contentElementId}/merges`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        direction: "previous",
        content: this.input.content,
      }),
    });
  }

  mergeNext() {
    fetch(`/content_elements/${this.contentElementId}/merges`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        direction: "next",
        content: this.input.content,
      }),
    });
  }

  indent() {
    fetch(`/content_elements/${this.contentElementId}/indentations`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        content: this.input.content,
        direction: "indent",
      }),
    });
  }

  unindent() {
    fetch(`/content_elements/${this.contentElementId}/indentations`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({
        content: this.input.content,
        direction: "unindent",
      }),
    });
  }

  destroy() {
    fetch(`/content_elements/${this.contentElementId}/deletions`, {
      method: "DELETE",
      headers: this.headers,
    });
  }

  broadcastInputChange(inputHTML, excludeUserId) {
    const isCurrent = this.currentUserId === excludeUserId;
    const isVisible = document.hasFocus();
    if (isCurrent && isVisible) return;

    this.input.innerHTML = inputHTML;
    this.input.dispatchEvent(new InputEvent("input"));
  }

  displayCommandsDropdown() {
    document.getElementById(this.commandsDropdownId).click();
  }

  displayFootnoteModal() {
    this.input.restoreSavedRange();
    const selection = window.getSelection();
    let selectedContent;

    if (selection.rangeCount == 1)
      selectedContent = window.getSelection().toString().trim();

    this.input.restoreSavedRange();
    this.input.currentSelection.collapseToEnd();

    const beforeCursor = encodeURIComponent(this.input.contentBeforeCursor);
    const afterCursor = encodeURIComponent(this.input.contentAfterCursor);
    const queryParams = `before_cursor=${beforeCursor}&after_cursor=${afterCursor}&selected_text=${selectedContent}`;
    const src = `${this.footnoteModalUrl}?${queryParams}`;

    document.getElementById("modal").src = src;
  }

  displayLinkModal() {
    this.input.restoreSavedRange();
    const selection = window.getSelection();
    if (selection.rangeCount < 1) return;

    let href;
    let existingAnchors;
    const range = selection.getRangeAt(0);

    // Extract the HTML for the selected range
    const clonedSelectedContent = range.cloneContents();
    const divSelected = document.createElement("div");
    divSelected.appendChild(clonedSelectedContent);
    const selectedContent = divSelected.innerHTML.replace(/<!---->/g, "");

    // Extract the HTML before the selected range
    const rangeBefore = document.createRange();
    rangeBefore.setStart(this.input, 0);
    rangeBefore.setEnd(range.startContainer, range.startOffset);
    const clonedContentBefore = rangeBefore.cloneContents();
    const divBefore = document.createElement("div");
    divBefore.appendChild(clonedContentBefore);
    existingAnchors = divBefore.querySelectorAll("a");
    const divBeforeAnchor = existingAnchors[existingAnchors.length - 1];

    // Extract the HTML after the selected range
    const rangeAfter = document.createRange();
    rangeAfter.setStart(range.endContainer, range.endOffset);
    rangeAfter.setEnd(this.input, this.input.childNodes.length);
    const clonedContentAfter = rangeAfter.cloneContents();
    const divAfter = document.createElement("div");
    divAfter.appendChild(clonedContentAfter);
    existingAnchors = divAfter.querySelectorAll("a");
    const divAfterAnchor = existingAnchors[0];

    const anchorsExist = divBeforeAnchor && divAfterAnchor;

    if (anchorsExist && divBeforeAnchor.href === divAfterAnchor.href) {
      href = divBeforeAnchor.href;
      divBeforeAnchor.remove();
      divAfterAnchor.remove();
    }

    const contentAfter = divAfter.innerHTML.replace(/<!---->/g, "");
    const contentBefore = divBefore.innerHTML.replace(/<!---->/g, "");

    // Encode the content for the query params
    const before = encodeURIComponent(contentBefore);
    const selected = encodeURIComponent(selectedContent);
    const after = encodeURIComponent(contentAfter);
    href = href ? encodeURIComponent(href) : "";

    // Build src
    const queryParams = `before=${before}&selected=${selected}&after=${after}&href=${href}`;
    const src = `${this.linkModalUrl}?${queryParams}`;

    // Display the modal
    document.getElementById("modal").src = src;
  }

  createRenderRoot() {
    return this;
  }

  async setDisabledState() {
    if (!this.disableUnlessAuthor) return;
    if (this.currentUserId === this.authorUserId) return;

    // sleep for a moment to ensure the input is rendered
    await new Promise((resolve) => setTimeout(resolve, 1));
    this.input.removeAttribute("contenteditable");
  }

  get input() {
    return this.querySelector("content-element-input");
  }

  get previousBlock() {
    return this.previousElementSibling;
  }

  get nextBlock() {
    return this.nextElementSibling;
  }

  get previousInput() {
    return this.previousBlock?.input;
  }

  get nextInput() {
    return this.nextBlock?.input;
  }

  get toolbar() {
    return this.querySelector("toolbar-element");
  }

  get contentElementId() {
    return this.dataset.contentElementId;
  }

  get currentUserId() {
    return document.querySelector('meta[name="user-id"]').content;
  }

  get headers() {
    return {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      "Content-Type": "application/json",
    };
  }
}
