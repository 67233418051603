import { Controller } from "stimulus";

export default class extends Controller {
  static values = { observer: String };

  connect() {
    this.observer = new IntersectionObserver(this._toggle.bind(this));
    const observerElement = document.getElementById(this.observerValue);
    this.observer.observe(observerElement);
  }

  disconnect() {
    this.observer.disconnect();
    this.observer = null;
  }

  scrollUp() {
    const main = document.querySelector("main");
    main.scrollTop = 0;
  }

  _toggle([entry]) {
    if (entry.isIntersecting) {
      this.element.classList.add("opacity-0");
      this.element.classList.remove("opacity-100");
    } else {
      this.element.classList.remove("opacity-0");
      this.element.classList.add("opacity-100");
    }
  }
}
