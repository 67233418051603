import Conductor from "../helpers/conductor_helper";
import setUserState from "../helpers/user_state_helper";

export default class extends Conductor {
  static targets = ["toggleable", "focusInput"];

  static values = {
    key: String,
    isCollapsed: Boolean,
    addCloseListeners: Boolean,
  };

  initialize() {
    this.closeOnClick = this._closeOnClick.bind(this);
    this.closeOnEsc = this._closeOnEsc.bind(this);
  }

  connect() {
    if (this.addCloseListenersValue && this.isOpen) this._setCloseListeners();
  }

  disconnect() {
    this._clearCloseListeners();
  }

  toggle() {
    this.isClosed ? this.open() : this.close();
  }

  open() {
    if (this.isOpen) return;

    this.updateDisplay();
    this.isCollapsedValue = false;

    if (this.addCloseListenersValue) this._setCloseListeners();
  }

  close() {
    document.removeEventListener("click", this.closeOnClick);
    document.removeEventListener("keydown", this.closeOnEsc);
    if (this.isClosed) return;

    this.updateDisplay();
    this.isCollapsedValue = true;
  }

  resolveTurboFrames() {
    this.element
      .querySelectorAll('turbo-frame[loading="lazy"]')
      .forEach((frame) => frame.removeAttribute("loading"));
  }

  updateDisplay() {
    this.toggleables.forEach((element) => {
      const classes = element.dataset.classes?.split(",") || ["hidden"];
      classes.forEach((c) => element.classList.toggle(c));
    });

    if (this.shouldFocus) this.focusInputTarget.focus();
    this.updateUserState();
  }

  updateUserState() {
    if (!this.hasKeyValue) return;

    setUserState({
      method: "toggle_collapsible",
      args: [this.keyValue, this.element.id],
    });
  }

  _closeOnClick({ target }) {
    if (this.element.contains(target)) return;
    if (this.element === target) return;

    this.close();
  }

  _closeOnEsc({ key }) {
    if (key !== "Escape") return;
    this.close();
  }

  _setCloseListeners() {
    this._clearCloseListeners();

    // Make sure listeners don't pick up on the current event
    setTimeout(() => {
      document.addEventListener("click", this.closeOnClick);
      document.addEventListener("keydown", this.closeOnEsc);
    }, 0);
  }

  _clearCloseListeners() {
    document.removeEventListener("click", this.closeOnClick);
    document.removeEventListener("keydown", this.closeOnEsc);
  }

  get isOpen() {
    return !this.isCollapsedValue;
  }

  get isClosed() {
    return this.isCollapsedValue;
  }

  get shouldFocus() {
    return (
      this.hasFocusInputTarget &&
      !this.focusInputTarget.classList.contains("hidden")
    );
  }

  get toggleables() {
    return this.targets.findAll("toggleable");
  }
}
