import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    expandId: String,
    turboFrameId: String,
  };

  connect() {
    this.expandTrigger = document.getElementById(this.expandIdValue);
    this.turboFrame = document.getElementById(this.turboFrameIdValue);
    this.collapseButton = this.expandTrigger.querySelector("button");
  }

  expand(e) {
    // Check if the trigger is in a collapsed state
    if (this.expandTrigger.dataset.collapseIsCollapsedValue === "false") return;

    e.preventDefault();

    // First, we expand the section
    this.collapseButton.click();

    // If frame element has completed loading, we can already follow the link
    if (this.turboFrame.hasAttribute("complete")) {
      return this.element.click();
    }

    // If frame element is *currently* loading, we can follow the link after it completes
    if (this.turboFrame.hasAttribute("busy")) {
      this.turboFrame.addEventListener("turbo:frame-load", () => {
        this.element.click();
      });
      return;
    }

    // If frame element has not started loading, we need to start it
    // Set the event listener to check if the frame has completed loading
    this.turboFrame.addEventListener("turbo:frame-load", () => {
      this.element.click();
    });

    // Start the loading process:
    this.turboFrame.loading = null;
    this.turboFrame.reload();
  }
}
