import { LitElement } from "lit";
import { property, customElement } from "lit/decorators.js";

import NavigationController from "./controllers/navigation-controller";
import ProjectVariablesController from "./controllers/project-variables-controller";
import ClickableLinksController from "./controllers/clickable-links-controller";
import CursorPositionMixin from "./mixins/cursor-position-mixin";

import consumer from "../channels/consumer";

@customElement("content-element-input")
class ContentElementInput extends CursorPositionMixin(LitElement) {
  @property({ type: String })
  blockType;

  @property({ type: String })
  blockId;

  // Set default to false
  @property({ type: Boolean })
  allowFormatting = false;

  @property({ type: Boolean })
  allowSplitting = false;

  constructor() {
    super();

    this.consumer = consumer;
    this.projectVariables = new ProjectVariablesController(this);
    this.clickableLinks = new ClickableLinksController(this);

    this.saveContent = this.saveContent.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.navigator = new NavigationController(this, {
      onNavigate: () => this.save(),
    });
  }

  connectedCallback() {
    super.connectedCallback();

    // Set container
    this.container = this.closest("[content_elements_container]");

    // Set event listeners
    if (this.isEnabled) this.toggleEventListeners("addEventListener");

    // Set focus state
    if (this.contentElement.shouldFocus) this.focus();
  }

  disconnectedCallback() {
    this.toggleEventListeners("removeEventListener");
    super.disconnectedCallback();
  }

  toggleEventListeners(action) {
    this[action]("debounced:input", this.saveContent);
    this[action]("keydown", this.handleKeydown);
    this[action]("paste", this.handlePaste);
  }

  createRenderRoot() {
    return this;
  }

  handleKeydown(event) {
    // Tab + Shift
    if (event.key === "Tab" && event.shiftKey) {
      return this.removeBulletIndentation(event);
    }

    // Tab
    if (event.key === "Tab") {
      return this.addBulletIndentation(event);
    }

    // Enter
    if (event.key === "Enter") {
      return this.splitContent(event);
    }

    // Backspace
    if (event.key === "Backspace") {
      return this.mergeOnBackspace(event);
    }

    // Delete
    if (event.key === "Delete") {
      return this.mergeOnDelete(event);
    }

    // Cmd + K
    if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
      return this.displayLinkModal(event);
    }

    // Formatting
    if (event.key === "u" || event.key === "b" || event.key === "i") {
      if (event.metaKey || event.ctrlKey) {
        return this.handleFormatting(event);
      }
    }

    // Display commands dropdown
    if (event.key === "/") {
      return this.displayCommandsDropdown(event);
    }
  }

  // "/"
  displayCommandsDropdown(event) {
    if (event.metaKey) return;
    if (!this.isEmpty) return;

    event.preventDefault();
    event.stopPropagation();

    this.contentElement.displayCommandsDropdown();
  }

  // "enter"
  splitContent(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.allowSplitting) return;

    // Ensure websocket connection is open
    this._ensureConnection();

    // Add callback to focus on new input after split
    this.container.addEventListener(
      "content_elements:split",
      () => this.reload.nextInput?.focus(),
      { once: true }
    );

    // Perform split
    this.contentElement.split(
      this.contentBeforeCursor,
      this.contentAfterCursor
    );
  }

  // "backspace"
  mergeOnBackspace(event) {
    // Ensure cursor is at the start
    if (!this.cursorAtStart) return;

    // Ensure websocket connection is open
    this._ensureConnection();

    // Get the previous block
    const previousBlock = this.contentElement.previousBlock;

    // Destroy content element if previous block is an image
    if (previousBlock && previousBlock.isImage) {
      return this.contentElement.destroy();
    }

    // Ensure the user doesn't currently have highlighted text
    if (this.hasHighlightedText) return;

    // Ensure there's an earlier input element to merge with
    if (!this.previousInput) return;

    // Ensure splitting is allowed on the previous element
    if (!this.previousInput.allowSplitting) return;

    // Prevent default backspace behavior
    event.preventDefault();
    event.stopPropagation();

    // Perform merge with previous input
    const previousInput = this.previousInput;
    const prevBlock = previousInput.contentElement;

    // Ensure placeholder is hidden on previousElement
    previousInput.dataset.placeholder = "";

    // Focus on previous input + get cursor position
    previousInput.moveCursorToEnd();
    const cursorPosition = previousInput.cursorPosition;

    // Set listener to move cursor back to original position
    if (prevBlock && this.hasContent) {
      this.container.addEventListener(
        "content_elements:merge_previous",
        () => previousInput.reload.setCursorAt(cursorPosition),
        { once: true }
      );
    }

    // Perform merge
    this.contentElement.mergePrevious();
  }

  // "delete"
  mergeOnDelete(event) {
    const nextInput = this.nextInput;
    const nextBlock = this.contentElement.nextBlock;

    if (!nextInput) return;
    if (!this.cursorAtEnd) return;
    if (this.hasHighlightedText) return;

    // Ensure websocket connection is open
    this._ensureConnection();

    if (nextBlock && nextBlock.isImage) {
      return this.contentElement.destroy();
    }

    event.preventDefault();
    event.stopPropagation();

    // Get cursor position
    const cursorPosition = this.cursorPosition;

    // Set listener to move cursor back to original position
    if (nextInput.hasContent) {
      this.container.addEventListener(
        "content_elements:merge_next",
        () => this.reload.setCursorAt(cursorPosition),
        { once: true }
      );
    }

    // Perform merge
    this.contentElement.mergeNext();
  }

  // "tab"
  addBulletIndentation(event) {
    // Override behavior if if completing a suggestion (content_elements/completions_controller.js)
    if (this.dataset.suggestion) return;

    event.preventDefault();
    event.stopPropagation();

    // Ensure websocket connection is open
    this._ensureConnection();

    // Get proxy element + cursor position
    const currentInput = this;
    const cursorPosition = this.cursorPosition;

    // Set listener
    this.container.addEventListener(
      "content_elements:indent",
      () => currentInput.reload.setCursorAt(cursorPosition),
      { once: true }
    );

    // Indent element
    this.contentElement.indent();
  }

  // "shift+tab"
  removeBulletIndentation(event) {
    event.preventDefault();
    event.stopPropagation();

    // Ensure websocket connection is open
    this._ensureConnection();

    // Get proxy element + cursor position
    const currentInput = this;
    const cursorPosition = this.cursorPosition;

    // Set listener
    this.container.addEventListener(
      "content_elements:unindent",
      () => currentInput.reload.setCursorAt(cursorPosition),
      { once: true }
    );

    // Unindent element
    this.contentElement.unindent();
  }

  // "cmd+k,ctrl+k"
  displayLinkModal(event) {
    event.preventDefault();
    if (!this.hasHighlightedText) return;
    if (!this.allowFormatting) return;

    this.contentElement.displayLinkModal();
  }

  // "debounced:input"
  saveContent(event) {
    if (this.projectVariables.dropdownIsActive) return;

    // Ensure websocket connection is open
    this._ensureConnection();

    event.stopPropagation();
    this.save();
  }

  // "paste"
  handlePaste(event) {
    event.preventDefault();

    const clipboardData = (event.originalEvent || event).clipboardData;

    const text = clipboardData.getData("text/plain");
    const element = document.createElement("div");
    element.innerText = text;

    document.execCommand(
      "insertText",
      false,
      element.innerText.replace(/[\n\r\t]/gm, "")
    );
  }

  // "formatting"
  handleFormatting(event) {
    if (!this.allowFormatting) event.preventDefault();
  }

  save() {
    if (!this.consumer.connection.isOpen())
      throw "The ActionCable connection is not open";

    this.contentElement.save();
  }

  _isAlphaNumeric(str) {
    return /^[a-z0-9]$/i.test(str);
  }

  _ensureConnection() {
    if (this.consumer.connection.isOpen()) return;

    alert(
      "A websocket connection has not been established.\n\nPlease refresh the page."
    );

    throw "The ActionCable connection is not open";
  }

  get contentElement() {
    return document.getElementById(this.blockId);
  }

  get previousInput() {
    return this.contentElement?.previousInput;
  }

  get nextInput() {
    const contentElement = this.reload.contentElement;
    return contentElement?.nextInput;
  }

  get isSmall() {
    if (this._isSmall) return this._isSmall;

    return (this._isSmall = this.contentElement.dataset.size === "sm");
  }

  get content() {
    return this.innerHTML.replaceAll(/<br>|<!---->|<mark>|<\/mark>/g, "");
  }

  get reload() {
    return document.getElementById(this.id);
  }

  get hasContent() {
    return this.content.length > 0;
  }

  get isEnabled() {
    return this.getAttribute("contenteditable") === "true";
  }
}
