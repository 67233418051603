import { Controller } from "stimulus";

export default class extends Controller {
  static values = { maxRows: { type: Number, default: 12 } };

  initialize() {
    this.autogrow = this._autogrow.bind(this);
    this.rowHeight = this._setRowHeight();
    this.borderWidth = this._setBorderWidth();

    this.originalRows = this.element.rows;
    this.originalHeight = this.element.style.height;
    this.form = this.element.closest("form");
  }

  connect() {
    this.minHeight = this.rowHeight * this.element.rows;
    this.maxHeight = this.maxRowsValue * this.rowHeight;
    this.element.addEventListener("input", this.autogrow);
    this.element.addEventListener("focus", this.autogrow);

    if (this.form) {
      this.form.addEventListener("turbo:submit-end", this.autogrow);
    }

    // Pause for a moment to allow textarea to expand to initial height, then run autogrow
    setTimeout(this.autogrow, 50);
  }

  disconnect() {
    this.element.removeEventListener("input", this.autogrow);
    this.element.removeEventListener("focus", this.autogrow);

    if (this.form) {
      this.form.removeEventListener("turbo:submit-end", this.autogrow);
    }
  }

  _autogrow() {
    // Force re-print before calculating the scrollHeight value.
    this.element.style.height = "auto";
    this.element.style.height = `${this.newHeight}px`;
  }

  _setRowHeight() {
    const styles = window.getComputedStyle(this.element);
    const lineHeight = styles.getPropertyValue("line-height");

    return parseInt(lineHeight);
  }

  _setBorderWidth() {
    const styles = window.getComputedStyle(this.element);
    const borderTop = styles.getPropertyValue("border-top-width");
    const borderBottom = styles.getPropertyValue("border-bottom-width");

    return parseInt(borderTop) + parseInt(borderBottom);
  }

  get newHeight() {
    if (this.currentHeight > this.maxHeight) return this.maxHeight;

    if (this.currentHeight < this.minHeight) return this.minHeight;

    return this.currentHeight + this.borderWidth;
  }

  get currentHeight() {
    return this.element.scrollHeight;
  }

  _resetSize() {
    this.element.rows = this.originalRows;
    this.element.style.height = this.originalHeight;
  }
}
