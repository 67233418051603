import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "loading"];
  static values = { url: String };

  initialize() {
    this.handleKeydown = this._keydown.bind(this);
  }

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.handleKeydown);
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.handleKeydown);
    }
  }

  // "cmd + control + p"
  _keydown(event) {
    if (!event.metaKey) return;
    if (!event.ctrlKey) return;
    if (event.key !== "p") return;

    // Prevent default
    event.preventDefault();

    // Run proofread logic
    this.proofread();
  }

  proofread() {
    if (this.hasNoContent) return;

    // Display loading state and disable element
    this.loadingTarget.innerHTML = this.loadingHTML;
    this.inputTarget.setAttribute("contenteditable", false);
    this.element.blur();

    // Send request to the proofreads endpoint
    fetch(this.urlValue, this.options);
  }

  get csrf() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  get options() {
    return { method: "GET", headers: { "X-CSRF-Token": this.csrf } };
  }

  get hasNoContent() {
    let result = false;
    if (this.inputTarget.tagName.toLowerCase() === "textarea") {
      result = this.inputTarget.value.trim() === "";
    } else {
      result = this.inputTarget.innerText.trim() === "";
    }

    return result;
  }

  get loadingHTML() {
    return `
      <div class="w-fit flex items-center space-x-1.5 my-1">
        <span class="relative flex h-2 w-2">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-600 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-2 w-2 bg-emerald-600"></span>
        </span>
        <p class="text-gray-800 text-xs font-medium">Proofreading content...</p>
      </div>
    `;
  }
}
