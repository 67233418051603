export const setUserState = (params) => {
  const path = "/user_state";
  const method = "PATCH";
  const csrf = document.querySelector("meta[name=csrf-token]").content;
  const contentType = "application/json";
  const headers = { "Content-Type": contentType, "X-CSRF-Token": csrf };

  fetch(path, {
    method: method,
    headers: headers,
    body: JSON.stringify(params),
  });
};

export default setUserState;
