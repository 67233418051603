// Automatically completes content element content
// Calls Groq (Llama) API to generate completion
// See completions_controller.js for similar functionality
import { Controller } from "stimulus";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = { id: String };
  static targets = ["input"];

  initialize() {
    this.timer = 1000;
    this.minCharacters = 35;

    const variant = "variant=autocomplete";
    const path = `/content_elements/${this.idValue}/completions/new`;
    this.url = `${path}?${variant}`;
  }

  connect() {
    this.inputTarget.addEventListener("focus", this._start.bind(this));
    this.inputTarget.addEventListener("blur", this._end.bind(this));
  }

  disconnect() {}

  _start() {
    this.subscription = this._createSubscription();
    this._setTimer();
    this.element.addEventListener("input", this._setTimer.bind(this));
  }

  _end() {
    this.subscription.unsubscribe();
    this.element.removeEventListener("input", this._setTimer.bind(this));
    this.lastInput = null;
  }

  _setTimer() {
    this.lastInput = this.now;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this._autocomplete(), this.timer);
  }

  _createSubscription() {
    const options = { channel: "StreamChannel", stream_key: this.streamKey };

    return consumer.subscriptions.create(options, {
      received: ({ suggestion }) => {
        // Unset running state
        this.isRunning = false;

        // Return if cancelled
        if (this.isCancelled) return;

        // Return if a suggestion is already present
        if (this.hasSuggestion) return;

        // Update suggestion
        if (suggestion) this.inputTarget.dataset.suggestion = suggestion;
      },
    });
  }

  _autocomplete() {
    if (!this.allowAutocomplete) return;

    this.isRunning = true;
    this.isCancelled = false;
    this._cancelOnInput();
    fetch(this.url);
  }

  _cancelOnInput() {
    this.inputTarget.addEventListener(
      "input",
      () => (this.isCancelled = true),
      { once: true }
    );
  }

  get allowAutocomplete() {
    if (!this.lastInput) return false;

    if (this.now - this.lastInput < this.timer) return false;

    if (this.hasSuggestion) return false;

    if (!this.inputTarget.cursorAtEnd) return false;

    if (!this.hasSufficientCharacters) return false;

    if (this.isRunning) return false;

    return true;
  }

  get now() {
    return Date.now();
  }

  get hasSuggestion() {
    const suggestion = this.inputTarget.dataset.suggestion;
    return suggestion && suggestion.length > 0;
  }

  get hasSufficientCharacters() {
    return this.inputTarget.innerText.length > this.minCharacters;
  }

  get streamKey() {
    const userId = document.querySelector('meta[name="user-id"]').content;
    return `${this.idValue}_${userId}`;
  }
}
