import Tribute from "tributejs";

class ProjectVariablesController {
  constructor(host) {
    this.host = host;
    host.addController(this);
  }

  hostConnected() {
    this.tribute = new Tribute(this._tributeOptions);
    this.tribute.detach(this.host);
    this.tribute.attach(this.host);
  }

  get dropdownIsActive() {
    return this.tribute._isActive;
  }

  get _tributeOptions() {
    return {
      values: Object.keys(this.variables).map((key) => {
        return { key: key, value: this.variables[key] };
      }),
      trigger: "$",
      requireLeadingSpace: true,
      replaceTextSuffix: "",
      spaceSelectsMatch: false,
      selectClass: "bg-gray-50",
      searchOpts: {
        pre: "<span class='bg-yellow-200'>",
        post: "</span>",
      },
      selectTemplate: (item) => {
        return item ? `$${item.original.key}&nbsp;` : null;
      },
      noMatchTemplate: () => {
        return;
      },
      containerClass:
        "overflow-hidden border border-gray-100 cursor-pointer rounded-xl bg-white text-sm max-w-64 shadow-pretty project-variable-dropdown",
      menuItemTemplate: (item) => {
        return `<div class="flex flex-col justify-between hover:bg-gray-50 focus:bg-gray-50 focus:outline-none py-2 pl-3 pr-12">
        <p class="whitespace-nowrap shrink-0">$${item.string}</p>
        <p class="text-gray-300 whitespace-pre-wrap flex-1 text-sm">${item.original.value}</p>
      </div>`;
      },
    };
  }

  get variables() {
    if (this._variables) return this._variables;

    const variableMeta = document.querySelector("meta[name=project-variables]");

    if (variableMeta) {
      this._variables = JSON.parse(variableMeta.content);
    } else {
      this._variables = this.defaultVariables;
    }

    return this._variables;
  }

  get defaultVariables() {
    return {
      PROJECT_NAME: "",
      CLIENT_NAME: "",
      ADVISOR_NAME: "",
    };
  }
}

export default ProjectVariablesController;
