const ReflexProxyMixin = (superClass) =>
  class extends superClass {
    afterProxy(action, fn) {
      this.addEventListener(`afterProxy:${action}`, fn, {
        once: true,
      });
    }

    stimulate(reflex, args, caller = null) {
      this.dispatchEvent(
        new CustomEvent("stimulate", {
          detail: { reflex, args, caller },
        })
      );
    }
  };

export default ReflexProxyMixin;
