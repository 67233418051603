import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["list"];

  static values = {
    handle: { type: String, default: "[data-sortable-handle='true']" },
    increment: { type: Number, default: 0 },
    nested: { type: Boolean, default: false },
    positionField: { type: String, default: "order_position" },
    skipPersistence: { type: Boolean, default: false },
  };

  initialize() {
    this.sortables = [];
  }

  connect() {
    this.listCount = this.lists.length;
    this._setSortables();
    this._setFrameListener();
    this._setStreamListener();
  }

  disconnect() {
    this.sortables.forEach((sortable) => sortable.destroy());
  }

  _setSortables() {
    this.lists.forEach(this._setupList.bind(this));
  }

  // Resets the sortables after a list is rendered via Turbo Stream
  _setStreamListener() {
    document.addEventListener("turbo:before-stream-render", ({ target }) => {
      const targetId = target.getAttribute("target");

      // Return if the targetId is null
      if (!targetId) return;

      // Return if the targetId starts with a number (i.e. not a valid id)
      if (targetId.match(/^\d/)) return;

      // Get the target element by id
      const targetElement = this.element.querySelector(`#${targetId}`);

      if (this.element.id === targetId || targetElement) {
        // Sleep for a moment to allow the stream to render
        setTimeout(() => this._setSortables(), 500);
      }
    });
  }

  // Updates this.sortables after a nested list is rendered via Turbo Frame
  _setFrameListener() {
    this.element.addEventListener("turbo:frame-render", () => {
      const listCount = this.lists.length;

      if (listCount > this.listCount) {
        this.listCount = listCount;
        this._setSortables();
      }
    });
  }

  _setupList(list) {
    const existingSortable = this.sortables.find((s) => s.el === list);
    if (existingSortable) return;

    const sortable = Sortable.create(list, this._options(list));
    this.sortables.push(sortable);
  }

  _options(list) {
    return {
      animation: 150,
      delay: 100,
      delayOnTouchOnly: true,
      direction: "vertical",
      easing: "cubic-bezier(1, 0, 0, 1)",
      ghostClass: "opacity-25",
      group: false,
      handle: list.dataset.sortableHandle || this.handleValue,
      onEnd: ({ item, newIndex }) => this._persistOrder(item, newIndex),
    };
  }

  _persistOrder(item, newIndex) {
    if (this.skipPersistenceValue) return;

    const contentType = "application/json";
    const csrf = document.querySelector("meta[name=csrf-token]").content;
    const headers = { "Content-Type": contentType, "X-CSRF-Token": csrf };
    const field = item.dataset.sortableField || this.positionFieldValue;
    const value = newIndex + this.incrementValue;

    fetch(`/record_order/${item.dataset.gid}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({ field: field, value: value }),
    });
  }

  get lists() {
    return (
      this.targets.findAll("list") ||
      this.element.querySelectorAll(this.handleValue)
    );
  }
}
