class PresenceList extends HTMLElement {
  connectedCallback() {
    if (!this.displayCurrentUser) this._hideCurrentUser();
  }

  _hideCurrentUser() {
    const user = this.querySelector(`[data-user-id="${this.userId}"]`);
    if (user) user.remove();
  }

  get presenceContainer() {
    return this.closest("presence-container");
  }

  get displayCurrentUser() {
    return this.presenceContainer.dataset.displayCurrentUser === "true";
  }

  get userId() {
    return this.presenceContainer.dataset.userId;
  }
}

customElements.define("presence-list", PresenceList);
