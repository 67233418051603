import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["turboFrame"];

  initialize() {
    this.activeClass = "text-green-accent";
  }

  connect() {
    this.turboFrameTarget.addEventListener("turbo:frame-load", () => {
      this.links.forEach((link) => this._handleLink(link));

      const activeItem = this.turboFrameTarget.querySelector(this.activeClass);
      if (activeItem) activeItem.scrollIntoView(false);
    });
  }

  _handleLink(link) {
    const href = link.getAttribute("href");
    if (!href) return;
    if (href.startsWith("#")) return;
    if (link.dataset.turboFrame) return;

    link.dataset.turboFrame = "_top";
  }

  get links() {
    return this.element.querySelectorAll("a");
  }
}
