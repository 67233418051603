import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    waitForFrameLoad: Boolean,
    frameId: String,
    runOnConnect: Boolean,
    params: Object,
  };

  connect() {
    if (!this.runOnConnectValue) return;

    if (!this.waitForFrameLoadValue) return this._runWithCleanup();

    this.frame.addEventListener("turbo:frame-load", () =>
      this._runWithCleanup()
    );
  }

  _runWithCleanup() {
    this.run({ params: this.paramsValue });
    this.element.remove();
  }

  run({ params }) {
    // Connect to stimulus controller on params.target, as specified by params.identifier
    const controller = this.application.getControllerForElementAndIdentifier(
      document.getElementById(params.target),
      params.identifier
    );

    // Run the action on the controller
    controller[params.action]({ params: params.actionParams });
  }

  get frame() {
    return document.getElementById(this.frameIdValue);
  }
}
