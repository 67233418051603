import { Controller } from "stimulus";

export default class extends Controller {
  static values = { key: String, specialCharacters: Array, toClick: String };
  static targets = ["container"];

  initialize() {
    this.container = this.hasContainerTarget ? this.containerTarget : document;
    this.handleHotkey = this._handleHotkey.bind(this);
  }

  connect() {
    this.container.addEventListener("keydown", this.handleHotkey);
  }

  disconnect() {
    this.container.removeEventListener("keydown", this.handleHotkey);
  }

  _handleHotkey(event) {
    // Check for meta
    if (this.requiresMeta) {
      if (!event.metaKey) return;
    }

    // Check for ctrl
    if (this.requiresCtrl) {
      if (!event.ctrlKey) return;
    }

    // Check for key
    if (event.key !== this.keyValue) return;

    this.toClick.click();
  }

  get requiresMeta() {
    return this.specialCharactersValue.includes("meta");
  }

  get requiresCtrl() {
    return this.specialCharactersValue.includes("ctrl");
  }

  get toClick() {
    if (!this.hasToClickValue) return this.element;

    return document.getElementById(this.toClickValue);
  }
}
