import ApplicationController from "../controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["input", "options", "flagDropdown"];

  // Applies property (i.e. block or flag type) to the given element
  async applyProperty(event) {
    // Get property and value from the event
    const { property, value } = event.params;

    // Set event listener for after the element has been morphed
    this.element.addEventListener(
      "turbo:morphed",
      () => this._afterMorph(property),
      { once: true }
    );

    // Morph the element (via StimulusReflex)
    this.stimulate(
      `BlockPropertiesReflex#update_${property}`,
      this.element,
      value,
      this.hasInputTarget ? this.inputTarget.content : ""
    );
  }

  async removeProperty(event) {
    // Get property and value from the event
    const { property, value } = event.params;

    // Set event listener for after the element has been morphed
    this.element.addEventListener(
      "turbo:morphed",
      () => this._afterMorph(property),
      { once: true }
    );

    // Morph the element (via StimulusReflex)
    this.stimulate(
      `BlockPropertiesReflex#remove_${property}`,
      this.element,
      value
    );
  }

  _afterMorph(property) {
    if (this.hasInputTarget) this.inputTarget.moveCursorToEnd();
    if (property !== "flagging") return;

    // Toggle the check icon:
    // If the flag dropdown turbo frame has been loaded
    // (i.e. it has children) we reload the frame
    if (this.flagDropdownTarget.children.length > 0)
      this.flagDropdownTarget.reload();
  }
}
