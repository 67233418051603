import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["loadingSpinner", "button", "hide"];
  static values = { submitReflex: String };

  connect() {
    super.connect();
  }

  async submit() {
    this.hideTargets.forEach((target) => target.classList.add("hidden"));
    this.loadingSpinnerTarget.classList.remove("hidden");
    await this.stimulate(this.submitReflexValue, this.buttonTarget);
  }
}
