import { Controller } from "stimulus";

export default class extends Controller {
  static values = { path: String };

  connect() {
    Turbo.visit(this.pathValue);
    this.element.remove();
  }
}
