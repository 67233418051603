import { Idiomorph } from "idiomorph/dist/idiomorph.esm";
import { StreamActions } from "@hotwired/turbo";

const allowMorph = (node) => {
  // Ensure node exists
  if (!node) return true;

  // Check if node has a populated dataset
  const dataset = node.dataset;
  if (!dataset) return true;

  // Do *not* allow morph if `preventMorph` is set to `true`
  if (dataset.preventMorph) return false;

  // Default to allowing morph
  return true;
};

StreamActions.morph = function () {
  const target = this.getAttribute("target");
  const existingNode = document.getElementById(target);
  if (!existingNode) return;

  const template = this.querySelector("template").content;
  const newNode = template.firstElementChild.cloneNode(true);

  Idiomorph.morph(existingNode, newNode, {
    callbacks: { beforeNodeMorphed: (node) => allowMorph(node) },
  });

  // Create turbo:morphed event
  const event = new CustomEvent("turbo:morphed", {
    bubbles: true,
    cancelable: true,
  });

  // Dispatch turbo:morphed event
  existingNode.dispatchEvent(event);
};
