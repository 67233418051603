// Sends PATCH request to `toggle_asked#update` when user toggles `is_asked`
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { linkId: String };

  run() {
    this.link.click();
  }

  get link() {
    return document.getElementById(this.linkIdValue);
  }
}
