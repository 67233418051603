import { Controller } from "stimulus";
import Dropzone from "../helpers/dropzone_helper";

export default class extends Controller {
  static targets = ["container", "form", "input"];

  initialize() {
    new Dropzone(this.element, this.dropzoneOptions);
  }

  _onHover() {
    this.element.classList.add("shadow-pretty");

    if (!this.hasContainerTarget) return;
    this.containerTarget.classList.add("border-dashed", "border-gray-800");
  }

  _dragLeave() {
    this._removeStyles();
  }

  _allowUpload() {
    return true;
  }

  _onDrop() {
    this._removeStyles();
  }

  _removeStyles() {
    this.element.classList.remove("shadow-pretty");

    if (!this.hasContainerTarget) return;
    this.containerTarget.classList.remove("border-dashed", "border-gray-800");
  }

  get dropzoneOptions() {
    return {
      input: this.inputTarget,
      form: this.formTarget,
      onHover: this._onHover.bind(this),
      onDrop: this._onDrop.bind(this),
      dragLeave: this._dragLeave.bind(this),
      allowUpload: this._allowUpload.bind(this),
    };
  }
}
