import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];

  initialize() {
    this.activeClasses = ["font-bold", "underline"];
  }

  selectItem({ target, params, metaKey, ctrlKey }) {
    // Check if the user hit command + click (open in new tab)
    if (metaKey || ctrlKey) return;

    // Unselect all items
    this.itemTargets.forEach((item) =>
      item.classList.remove(...this.activeClasses)
    );

    // Select the clicked item
    target.classList.add(...this.activeClasses);

    // Update history state
    history.pushState(null, "", target.href);

    // Update the active crumb
    this.activeCrumb.innerHTML = params.title;
  }

  get activeCrumb() {
    return document.getElementById("active_crumb");
  }
}
