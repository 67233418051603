// This is the parent conductor of the collapse_controller
// By convention, the parent name is the plural of the child name
import Conductor from "../helpers/conductor_helper";

// Create a kid controller by extending stimulus-conductor controller
export default class extends Conductor {
  static targets = ["expandTrigger", "collapseTrigger"];

  static values = {
    child: { type: String, default: "collapse" },
    query: String,
  };

  expandAll() {
    this.controllers.forEach((controller) => this._expand(controller));

    this.expandTriggerTarget.classList.add("hidden");
    this.collapseTriggerTarget.classList.remove("hidden");
  }

  collapseAll() {
    this.controllers.forEach((controller) => this._collapse(controller));

    this.collapseTriggerTarget.classList.add("hidden");
    this.expandTriggerTarget.classList.remove("hidden");
  }

  _expand(controller) {
    // If the children are `collapses` controllers: run expandAll on them
    if (this.childValue === "collapses") {
      return controller.expandAll();
    }

    // Otherwise: run open on the child `collapse` controllers and resolve the turbo frames
    controller.open();
    controller.resolveTurboFrames();
  }

  _collapse(controller) {
    // If the children are `collapses` controllers: run collapseAll on them
    if (this.childValue === "collapses") {
      return controller.collapseAll();
    }

    // Otherwise: run close on the child `collapse` controller
    controller.close();
  }

  _fetchController(element) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      this.childValue
    );
  }

  get controllers() {
    if (this.hasQueryValue) return this.manuallyFetchControllers;

    return this.collapseControllers || this.manuallyFetchControllers;
  }

  get manuallyFetchControllers() {
    return Array.from(this.conductors).reduce((acc, conductor) => {
      const controller = this._fetchController(conductor);
      return controller ? [...acc, controller] : acc;
    }, []);
  }

  get conductors() {
    const query = this.hasQueryValue ? this.queryValue : this.defaultQuery;
    return this.element.querySelectorAll(query);
  }

  get defaultQuery() {
    return `[data-controller*='${this.childValue}']`;
  }
}
