import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    redirectPath: String, // redirect after successful auth
  };

  connect() {
    if (this.hasRedirectPathValue) {
      this._closeWindowAndRedirect();
      return;
    }

    this.popup = null;
    this.popupWidth = 500;
    this.popupHeight = 600;
  }

  // Google sign-in logic

  openLoginWindow() {
    if (this.popup === null || this.popup.closed) {
      this.popup = window.open("", "_blank", this.options);
      this._insertFormInPopup();
      this.popup.document.body.querySelector("form").submit();
    } else {
      this.popup.focus();
    }
  }

  // Helpers

  // Inserts a form into the popup window, which we then submit to trigger OAuth flow
  // This is because OmniAuth now defaults to only POST as the allowed request_phase method
  _insertFormInPopup() {
    this.popup.document.body.insertAdjacentElement(
      "afterBegin",
      this.googleAuthForm
    );
  }

  _closeWindowAndRedirect() {
    window.opener.location = this.redirectPathValue;
    window.close();
  }

  get options() {
    return `toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=${this.left},width=${this.popupWidth},height=${this.popupHeight}`;
  }

  get left() {
    return window.innerWidth / 2 - this.popupWidth / 2;
  }

  get googleAuthForm() {
    // Get form
    const form = document.getElementById("google-auth-form");

    // Build state
    const state = JSON.stringify({
      remember_me: document.getElementById("user_remember_me").checked,
      google_oauth_csrf: form.dataset.googleOauthCsrf,
    });

    // Append state to action
    form.action += `?${new URLSearchParams({ state: state }).toString()}`;

    // Return updated form
    return form;
  }
}
